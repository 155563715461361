import getEnv from "../env";
import { PREVIEW_MODE_FLAG } from "./constants";
import { isServer } from "~/utils/isBrowser";

export const getIsPreviewMode = () => {
  if (isServer()) {
    return null;
  }
  return localStorage.getItem(PREVIEW_MODE_FLAG) === "true";
};

export const setPreviewMode = () => {
  if (getEnv().REACT_APP_ENV === "production") {
    return;
  }
  if (isServer()) {
    return null;
  }

  localStorage.setItem(PREVIEW_MODE_FLAG, "true");
};

export const setLiveMode = () => {
  if (getEnv().REACT_APP_ENV === "production") {
    return;
  }
  if (isServer()) {
    return null;
  }

  localStorage.removeItem(PREVIEW_MODE_FLAG);
};
